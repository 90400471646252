/**
 * @file urls that ignored auth check
 * @author Mingze Ma
 */

export default [
  '/',
  '/login',
  '/register',
];
